import styled from "styled-components";
import { HeroContentProps } from "./HeroContent";

export const HeroContentContainer = styled.div<HeroContentProps>`
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionWrapper = styled.div<HeroContentProps>`
  background-color: ${(props) => props.theme.colors.transparent};
  color: ${(props) => props.theme.colors.black};
  font-size: 20px;

  max-width: 1200px;
  padding: 104px 40px 0 40px;
  margin: 0 auto 120px;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: visible;
`;

export const HeroTitle = styled.h1`
  text-align: center;
  letter-spacing: -2px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 56px;
  line-height: 110%;

  @media screen and (max-width: 991px) {
    font-size: 48px;
  }

  @media screen and (max-width: 479px) {
    margin-bottom: 32px;
    font-size: 40px;
    line-height: 100%;
  }
`;

export const HeroText = styled.div`
  padding-bottom: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 170%;

  @media screen and (max-width: 991px) {
    font-size: 18px;
  }

  @media screen and (max-width: 479px) {
    padding-bottom: 32px;
    font-size: 16px;
    line-height: 150%;
  }
`;

export const HeroImage = styled.img`
  background-color: url(${(props) => props.src});
  width: 650px;
  //height: 433px;
  margin-top: 64px;

  @media screen and (max-width: 991px) {
    width: 480px;
  }

  @media screen and (max-width: 767px) {
    width: 400px;
  }

  @media screen and (max-width: 479px) {
    margin-top: 40px;
    width: 100%;
  }
`;
