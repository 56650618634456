export default {
  app: {
    title: "Test 123",
  },
  refresh: {
    title: "Are you active?",
    cta: "You were registered as not active, please confirm that you are active in the next minute, if you don't you will be logged out.",
  },
  common: {
    language: "Language",
    english: "English",
    serbian: "Serbian",
    close: "Close",
    dataGridExample: "Data Grid Example",
    trademark: "TM",
    search: "Search",
    error: "Error",
    continue: "Continue",
    labelUsername: "Username",
    labelEmail: "Email",
    labelPassword: "Password",
    next: "Next",
    nextPage: "Next page",
    previousPage: "Previous page",
    back: "Back",
    goBack: "Go Back",
    ok: "Ok",
    done: "Done",
    confirm: "Confirm",
    printDownload: "Print/Download",
    cancel: "Cancel",
    remove: "Remove",
    invite: "Invite",
    save: "Save",
    complete: "Complete",
    download: "Download",
    yes: "Yes",
    no: "No",
    to: "to",
    select: "Select...",
    none: "None",
    date: {
      range: "{{start}} to {{end}}",
    },
    apply: "Apply",
    contactUs: "Kontaktirajte nas",
    tryForFree: "Probajte besplatno",
    supportLink: "support.inspectmaster@tremium.net",
    phoneNumber: "061/123-456",
  },
  pageTitles: {
    Home: "Home Page | Tremium Software",
    About: "About Us | Tremium Software",
    Career: "Career Page | Tremium Software",
  },
  pages: {
    home: "Home",
    login: "Login",
    register: "Register",
    forgotPassword: "Forgot password",
    error: "Error",
    notFound: "Not Found",
    profile: "Profile",
    settings: "Settings",
    dashboard: "Dashboard",
    about: "About",
    careers: "Careers",
    contact: "Contact",
  },
  register: {
    registerTitle: "Register",
    usernameRequired: "Username is required.",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    passwordRequired: "A Password is required.",
  },
  login: {
    welcome: "React template",
    dontHaveAccount: "Don't have an account? ",
    emailFormat: "Invalid email address format.",
    emailRequired: "An email or username is required.",
    noUsers: "There are no users with that email.",
    passwordStrength: "Your password is {{strength}}.",
    passwordLength: "Your password contain between 8 and 50 characters.",
    signUpRecommendation: "Sign up",
    email: "Please enter your email address or username to log in:",
    logInTitle: "Log In",
    logIn: "Log In",
    signUp: "Sign Up",
    usernameRequired: "Username is required.",
    passwordRequired: "A Password is required.",
    forgotYourPassword: "Forgot your password?",
    forgotPasswordEmail: "Email",
    useDifferentEmail: "Use different email address or username",
  },
  password: {
    weak: "weak",
    average: "average",
    good: "good",
    strong: "strong",
  },
  forgotPassword: {
    title: "Forgot Password",
    label: "Send email",
    emailRequired: "An email is required.",
    emailFormat: "Invalid email address format.",
    forgotPassword: {
      title: "Forgot Password",
      subtitle:
        "Please answer the security question to gain access to your account:",
      label: "Reset Password",
    },
  },
  notFound: {
    text: "We're sorry but we couldn't find the page you were looking for.",
    goBack: "Go back to homepage",
  },
  errorPage: {
    text: "We're sorry, an internal server error came up. Please be patient or try again later.",
    goBack: "Go back to homepage",
    logout: "Logout",
  },
  apiErrors: {
    ClientIpAddressIsNullOrEmpty: "Client Ip address is null or empty",
    UsernameDoesNotExist: "Username does not exist",
    WrongCredentials: "Wrong credentials",
    SomethingWentWrong: "Something went wrong",
    WrongPasswordAccountIsLocked: "Wrong credentials, account is locked",
    AccountIsLocked: "Account is locked",
  },
  footer: {
    text: "Inspect Master <3> Copyright © 2023 </3> <1></1> <2>Tremium Software.</2> Sva prava zadržana.",
    navItems: {
      item1: "Početna",
      item2: "Benefiti",
      item3: "Demo",
      item4: "Pomoć",
      item5: "Kontakt",
    },
  },
  header: {
    navItems: {
      item1: "Početna",
      item2: "Benefiti",
      item3: "Pomoć",
      item4: "Kontakt",
      item5: "Prijavite se",
    },
  },
  sectionHero: {
    title: "Provera procesa. Automatizacija kontrole. Ušteda vremena.",
    text: "Inspect Master predstavlja rešenje za sve Vaše potrebe u oblasti provere bezbednosnih uslova na radu. Naša platforma pruža automatizaciju, standardizaciju i sistematsko vođenje podataka, rešavajući ključne izazove sa kojima se tradicionalni procesi provere suočavaju.",
    buttonLabel: "Kontaktirajte nas",
  },

  sectionWhy: {
    title: "Zašto baš Inspect Master?",
    cards: {
      card1: {
        title: "Automatizacija",
        text: "Proces provere je automatizovan od samog početka provere do pravljenja stručnog nalaza kao krajnjeg proizvoda. Automatizacija procesa štedi vreme i pruža efikasnost u radu.",
      },
      card2: {
        title: "Prilagodljivost",
        text: "Koristeći personalizovane šablone, naša platforma omogućava prilagođavanje procesa provere bilo kom tipu ispitivanja.",
      },
      card3: {
        title: "Bez grešaka",
        text: "Pripremom šablona koji precizno vode kroz proces provere smanjujete mogućnost greške i povećavate efikasnost u radu.",
      },
      card4: {
        title: "Ušteda vremena",
        text: "Zaboravite na vremenski zahtevne procedure i dugotrajno kreiranje izveštaja. Inspect Master automatski generiše stručne nalaze i izveštaje, štedeći vreme za unos i obradu podataka.",
      },
      card5: {
        title: "Administracija i struktura",
        text: "Naša aplikacija nudi jednostavno upravljanje svim parametrima potrebnim za kreiranje nalaza. Stručni nalaz je glavni dokument aplikacije, a naš sistem omogućava pretragu i filtriranje podataka prema Vašim potrebama.",
      },
    },
  },
  sectionInfo: {
    text: "Ručno vođenje podataka i nedostatak dokumentacije postaju prošlost uz naše softversko rešenje. Inspect Master omogućava brz i efikasan unos podataka putem računara, telefona i tableta čime se olakšava rad na terenu. <br /><br /> U cilju ubrzanja i standardizacije procesa provere, naša aplikacija generiše stručni nalaz kreiran prema unapred napravljenim šablonima i u skladu sa važećim propisima.",
  },
  sectionMakePossible: {
    title: "Šta omogućava <br /> Inspect Master?",
    items: [
      "Mogućnost unošenja rezultata ispitivanja kroz tekst, sliku ili tabelu",
      "Formiranje stručnog nalaza nakon unošenja podataka ispitivanja",
      "Eksportovanje stručnih nalaza u PDF formatu",
      "Automatsko slanje stručnih nalaza putem e-maila",
      "Mogućnost kopiranja postojećih nalaza",
      "Korišćenje uređaja za brzi unos podataka",
      "Arhiviranje stručnih nalaza i čuvanje rezervnih kopija na cloud-u",
      "Mogućnost kreiranja šablona koji služi za automatizaciju provere procesa i opreme za rad prema zahtevima i standardima specifičnim za određenu industriju",
      "Pretraga i brz pristup svim stručnim nalazima",
    ],
  },
  sectionTrial: {
    title: "Registrujte se prvih mesec <br /> dana besplatno!",
    text1: "Besplatna verzija uključuje:",
    text2:
      "Nakon isteka perioda od mesec dana, kontaktirajte nas za cenovne ponude i produžetak licence.",
    trialList: [
      "Neograničen broj korisnika i stručnih nalaza",
      "Slanje izveštaja na mejl u momentu zatvaranja nalaza",
      "Podešavanje štampe i pristup šablonima",
      "Kopiranje predmeta ispitivanja i stručnog nalaza",
      "Čuvanje izveštaja na Cloud-u u momentu zatvaranja nalaza",
      "Document management strana",
    ],
  },
  sectionFAQ: {
    title: "Često postavljana pitanja",
    note: "Još uvek imate pitanje?",
    questions: {
      question1: {
        question: "Kako se registrovati na platformu?",
        answer:
          "Uz samo jedan poziv ili mejl! Prvi nalog Vašeg tima na platformi otvara Inspect Master tim za podršku usled čega Vam dodeljuje ulogu administratora. Nakon toga, administrator ima mogućnost da dodaje ostale članove tima dodeljujući im različite uloge u okviru platforme. ",
      },
      question2: {
        question: "Koji su najvažniji segmenti Inspect Mastera?",
        answer:
          "Predmeti ispitivanja koji se sastoje iz više sekcija osnovni su deo aplikacije. Zato se oni kreiraju pre samog ispitivanja kako bi olakšali dalje provere. Pored predmeta ispitivanja, stručni nalazi su krajnji dokument koji se dobija nakon ispitivanja na terenu. Oni se generišu popunjavanjem rezultata ispitivanja na terenu.",
      },
      question3: {
        question: "Kako se predefinišu <i>parametri</i> ispitivanja?",
        answer:
          "U okviru platforme potrebno je definisati neophodne parametre: oblasti rada kojim se kompanija bavi (Tipovi ispitivanja), propise i standarde koji će se odnositi na ispitivanja, instrumente kojima se vrše ispitivanja, kao i klijente za koje se to ispitivanje obavlja. ",
      },
      question4: {
        question: "Kako se predefinišu <i>predmeti</i> ispitivanja?",
        answer:
          "Predmeti ispitivanja se mogu predefinisati na dva načina: uvozom iz zajedničke biblioteke ili samostalnim kreiranjem. Prilikom samostalnog kreiranja nalaza trebalo bi definisati tip nalaza, instrumente kojima se vrši ispitivanje i propise i standarde koji se koriste za ispitivanje. Nakon toga se pripremaju pitanja na koja je potrebno odgovoriti tokom popunjavanja stručnog nalaza. Takođe, moguće je unapred definisati zaključke, kao i odgovore na pitanja kako bi se na terenu optimizovalo vreme potrebno za unos. ",
      },
      question5: {
        question: "Kako se unose rezultati ispitivanja?",
        answer:
          "Odgovori na unapred pripremljena pitanja u stručnom nalazu se unose u vidu teksta, popunjavanjem tabele, u vidu slike ili biranjem jedne od ponuđenih opcija. Na kraju se formira zaključak - bilo pozitivan ili negativan. Jedan stručni nalaz može imati veći broj predmeta ispitivanja.",
      },
      question6: {
        question: "Koje su dodatne funkcionalnosti?",
        answer:
          "Pored primarnih fukcionalnosti, dodatne funkcionalnosti Inspect Mastera su automatsko čuvanje stručnih nalaza i šablona. Stručni nalazi se mogu sačuvati na Cloud-u ili direktno poslati klijentima na e-mail. Velika prednost su i šablonski, unapred definisani odgovori na najčešća pitanja koji se mogu automatski dodati u stručni nalaz, kao i već definisani šabloni ispitivanja.",
      },
    },
  },
  sectionAbout: {
    title: "O nama",
    text: "<1>Pre nekoliko godina javio nam se, sada već naš dugogodišnji partner, sa problemom koji je imao u svakodnevnom poslovanju. Bio mu je potreban alat koji će mu pomoći da optimizuje procese, efikasnije obavlja ispitivanja i generiše stručne nalaze. Tako je nastao Inspect Master - rešenje koje smo osmislili kao odgovor na svakodnevne izazove i problem iz realnog poslovanja u oblasti provere bezbednosnih uslova na radu. </1> <1>Inspect Master je produkt koji razvija tim IT softverske kompanije Tremium Software i na kome na dnevnom nivou radi marljivo unoseći najnovije promene i funkcionalnosti. Trudimo se da ostanemo dosledni potrebama naših partnera, ali i uvedemo dovoljno novina kako bismo proširili namenu naše platforme. </1>",
  },
  sectionContact: {
    title: "Kontaktirajte nas",
    text: "Inspect Master Vam daje mogućnost da odaberete funkcionalnosti koje najviše odgovaraju Vašim potrebama. Za informacije o cenovnim ponudama, kao i besplatnoj probnoj registraciji, kontaktirajte nas na mejl: <1> support.inspectmaster@tremium.net</1> ili pozivom na broj telefona <2>061/123-456</2>",
  },
};
