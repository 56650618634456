import React from "react";
import { useTranslation } from "react-i18next";
import {
  NavContainer,
  NavMobile,
  NavTablet,
  NavBarProps,
  NavBarExtendedProps,
  NavLink,
  ButtonWrapper,
} from "./Header.styled";
import useDevice from "../../hooks/useDevice";

const NavBar: React.FC<NavBarExtendedProps> = ({ isOpened, closeMenu }) => {
  const { t } = useTranslation();
  const { isMobile, isTablet } = useDevice();
  const headerNavItems = [
    {
      title: "header.navItems.item1",
      link: "#",
    },
    {
      title: "header.navItems.item2",
      link: "#benefits",
    },
    {
      title: "header.navItems.item3",
      link: "#help",
    },
    {
      title: "header.navItems.item4",
      link: "#contact",
    },
  ];
  return (
    <NavContainer>
      {isMobile ? (
        <NavMobile isOpened={isOpened}>
          <ul>
            {headerNavItems &&
              headerNavItems.map((el, index) => {
                return (
                  <li>
                    <NavLink onClick={closeMenu} key={index} href={el.link}>
                      {" "}
                      {t(el.title)}
                    </NavLink>
                  </li>
                );
              })}
            <ButtonWrapper isMobile={true} isTablet={false}>
              <a href="http://app.inspectmaster.net/" className="btn">
                {t("header.navItems.item5")}
              </a>
            </ButtonWrapper>
          </ul>
        </NavMobile>
      ) : isTablet ? (
        <NavTablet isOpened={isOpened}>
          <ul>
            {headerNavItems &&
              headerNavItems.map((el, index) => {
                return (
                  <li>
                    <NavLink onClick={closeMenu} key={index} href={el.link}>
                      {" "}
                      {t(el.title)}
                    </NavLink>
                  </li>
                );
              })}
            <ButtonWrapper isMobile={false} isTablet={true}>
              <a href="http://app.inspectmaster.net/" className="btn">
                {t("header.navItems.item5")}
              </a>
            </ButtonWrapper>
          </ul>
        </NavTablet>
      ) : (
        <></>
      )}
    </NavContainer>
  );
};
export default NavBar;
